// TODO: Move these into a Survey model
export const LanguageOptions = {
  arabic: {
    name: 'Arabic',
    localised: 'العربية',
    extended: 'Arabic (العربية)',
    code: 'AR'
  },
  english: {
    name: 'English',
    localised: 'English',
    extended: 'English (English)',
    code: 'EN'
  },
  french: {
    name: 'French',
    localised: 'Français',
    extended: 'French (français)',
    code: 'FR'
  },
  german: {
    name: 'German',
    localised: 'Deutsch',
    extended: 'German (Deutsch)',
    code: 'DE'
  },
  italian: {
    name: 'Italian',
    localised: 'Italiano',
    extended: 'Italian (italiano)',
    code: 'IT'
  },
  chinese: {
    name: 'Chinese',
    localised: 'Chinese',
    extended: 'Chinese (Traditional Chinese)',
    code: 'ZH'
  },
  slovene: {
    name: 'Slovene',
    localised: 'Slovene',
    extended: 'Slovene (slovene)',
    code: 'SL'
  },
  romansh: {
    name: 'Romansh',
    localised: 'Romansh',
    extended: 'Romansh (romansh)',
    code: 'RM'
  },
  polish: {
    name: 'Polish',
    localised: 'Polish',
    extended: 'Polish (polish)',
    code: 'PL'
  },
  chineseSimplified: {
    name: 'Chinese Simplified',
    localised: '简体中文',
    extended: 'Chinese (Simplified Chinese)',
    code: 'zh-Hans',
    // Use superscript for the little s
    displayCode: 'ZHˢ'
  }
};

export const getLanguage = code => {
  return Object.values(LanguageOptions).find(
    language => language.code === code
  );
};
